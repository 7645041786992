// extracted by mini-css-extract-plugin
export var addition = "styles-module--addition--583b3";
export var additionalInfoMobile = "styles-module--additionalInfoMobile--5f106";
export var agencyVisibleMobile = "styles-module--agencyVisibleMobile--407a1";
export var colData = "styles-module--colData--e2b88";
export var colHeaders = "styles-module--colHeaders--370b9";
export var comparePlans = "styles-module--comparePlans--a39fc";
export var customText = "styles-module--customText--7922f";
export var enterpriseVisibleMobile = "styles-module--enterpriseVisibleMobile--2ec09";
export var mobilePlanNav = "styles-module--mobilePlanNav--e01a1";
export var no = "styles-module--no--db0ca";
export var payAsYouGoVisibleMobile = "styles-module--payAsYouGoVisibleMobile--7c70b";
export var planName = "styles-module--planName--f7e10";
export var planPrice = "styles-module--planPrice--668fa";
export var planPricePeriod = "styles-module--planPricePeriod--c6f80";
export var priceAndButton = "styles-module--priceAndButton--e0ad4";
export var proVisibleMobile = "styles-module--proVisibleMobile--645b5";
export var scrolledPast = "styles-module--scrolledPast--fef15";
export var startButton = "styles-module--startButton--d4410";
export var starterVisibleMobile = "styles-module--starterVisibleMobile--429ef";
export var tableWrapper = "styles-module--tableWrapper--29787";
export var theadScrolledPastBackgroundHelper = "styles-module--theadScrolledPastBackgroundHelper--b8242";
export var tooltipHeader = "styles-module--tooltipHeader--fe101";
export var visuallyHidden = "styles-module--visuallyHidden--0ebc2";
export var yes = "styles-module--yes--eee77";