import React, { useRef } from "react";
import { concatClassNames as cn, accessibleOnClick } from "@system42/core";
// import { } from '../../system42'
import * as styles from "./styles.module.css";
import { useIsScrolledPast } from "../../../helpers";

export function MobilePlanNav(props) {
  const ref = useRef();

  const isScrolledPast = useIsScrolledPast(ref);

  const { className, value, onChange, ...containerProps } = props;

  return (
    <div
      ref={ref}
      className={cn(
        className,
        styles.mobilePlanNav,
        isScrolledPast && styles.scrolledPast
      )}
      {...containerProps}
    >
      <div
        {...accessibleOnClick(() => onChange("payg"))}
        className={cn(
          styles.item,
          styles.item_payg,
          value === "payg" && styles.item_active
        )}
      >
        Pay as
        <br />
        you go
      </div>
      <div
        {...accessibleOnClick(() => onChange("starter"))}
        className={cn(
          styles.item,
          styles.item_starter,
          value === "starter" && styles.item_active
        )}
      >
        Starter
      </div>
      <div
        {...accessibleOnClick(() => onChange("pro"))}
        className={cn(
          styles.item,
          styles.item_pro,
          value === "pro" && styles.item_active
        )}
      >
        Pro
      </div>
      <div
        {...accessibleOnClick(() => onChange("agency"))}
        className={cn(
          styles.item,
          styles.item_agency,
          value === "agency" && styles.item_active
        )}
      >
        Agency
      </div>
      {/*<div
      {...accessibleOnClick(() => onChange('enterprise'))}
      className={cn(styles.item, styles.item_enterprise, value === 'enterprise' && styles.item_active)}
    >
      Enterprise
    </div>*/}
    </div>
  );
}
