import React, { useEffect, useRef, useState } from "react";
import { concatClassNames as cn } from "@system42/core";
// import { } from '../../system42'
import { MobilePlanNav } from "./MobilePlanNav";
import * as styles from "./styles.module.css";
import { Button, ButtonPrimary } from "../../system42";
import {
  advertedPoolSize,
  getCreateAccountUrl,
  priceStarterMonthly,
  priceStarterYearly,
  priceAgencyMonthly,
  priceAgencyYearly,
  priceProMonthly,
  priceProYearly,
  pricePayAsYouGoTest,
  useLocalCurrencySymbol,
} from "../../helpers";
import { CompareTableRow } from "./CompareTableRow";
import { CompareTableCategoryBody } from "./CompareTableCategoryBody";

import imgPayAsYouGo from "../PricingFull2022/payAsYouGo.png";
import imgStarter from "../PricingFull2022/starter.png";
import imgPro from "../PricingFull2022/pro.png";
import imgAgency from "../PricingFull2022/agency.png";

export function ComparePlans(props) {
  const [activeColumnMobile, setActiveColumnMobile] = useState("pro");
  const [theadIsScrolledPast, setTheadIsScrolledPast] = useState(false);
  const currencySymbol = useLocalCurrencySymbol();
  const refTable = useRef();

  useEffect(() => {
    function handleScroll() {
      if (refTable.current) {
        const isScrolledPast = window.scrollY > refTable.current.offsetTop;
        setTheadIsScrolledPast(isScrolledPast);
      }
    }

    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { className, billingCycle, ...containerProps } = props;

  const priceStarter =
    billingCycle === "annual" ? priceStarterYearly : priceStarterMonthly;
  const priceAgency =
    billingCycle === "annual" ? priceAgencyYearly : priceAgencyMonthly;
  const pricePro = billingCycle === "annual" ? priceProYearly : priceProMonthly;
  const priceBillingCycle =
    billingCycle === "annual" ? "mo. billed yearly" : "month";

  return (
    <div
      className={cn(
        className,
        styles.comparePlans,
        activeColumnMobile === "payg" && styles.payAsYouGoVisibleMobile,
        activeColumnMobile === "starter" && styles.starterVisibleMobile,
        activeColumnMobile === "pro" && styles.proVisibleMobile,
        activeColumnMobile === "agency" && styles.agencyVisibleMobile,
        activeColumnMobile === "enterprise" && styles.enterpriseVisibleMobile
      )}
      {...containerProps}
    >
      <MobilePlanNav
        className={styles.mobilePlanNav}
        value={activeColumnMobile}
        onChange={setActiveColumnMobile}
      />

      <div className={styles.tableWrapper}>
        {theadIsScrolledPast && (
          <div className={styles.theadScrolledPastBackgroundHelper} />
        )}
        <table
          ref={refTable}
          summary={
            "Feature comparison of the four different Userbrain pricing models for conducting unmoderated remote user tests."
          }
        >
          <colgroup>
            <col className={styles.colHeaders} />
          </colgroup>

          <colgroup>
            <col className={styles.colData} />
            <col className={styles.colData} />
            <col className={styles.colData} />
            <col className={styles.colData} />
          </colgroup>

          <thead className={cn(theadIsScrolledPast && styles.scrolledPast)}>
            <tr>
              <td />
              <th className={styles.planName} id={"thPayg"} scope={"col"}>
                <div>
                  <img src={imgPayAsYouGo} alt="A pixalated pointing hand" />
                  Pay as you go
                </div>
              </th>
              <th className={styles.planName} id={"thStarter"} scope={"col"}>
                <div>
                  <img src={imgStarter} alt="Hand pressing a buzzer" />
                  Starter
                </div>
              </th>
              <th className={styles.planName} id={"thPro"} scope={"col"}>
                <div>
                  <img
                    src={imgPro}
                    alt="A spaceshuttle on turquoise background"
                  />
                  Pro
                </div>
              </th>
              <th className={styles.planName} id={"thAgency"} scope={"col"}>
                <div>
                  <img
                    src={imgAgency}
                    alt="Old Apple computer with a pixalated open mail envelope."
                  />
                  Agency
                </div>
              </th>
              {/*          <th className={styles.planName} id={'thEnterprise'} scope={'col'}>
            <div>
              <img src={imgEnterprise} alt="A corporate skyscraper on red background" />
              Enterprise
            </div>
          </th>*/}
            </tr>
            <tr>
              <th id="thPlanPrice" scope="row">
                <span className={styles.visuallyHidden}>Price</span>
              </th>
              <td headers="thPlanPrice">
                <div className={styles.priceAndButton}>
                  <div className={styles.planPrice}>
                    {currencySymbol}
                    {pricePayAsYouGoTest}{" "}
                    <span className={styles.planPricePeriod}>/ tester</span>
                  </div>
                  <Button
                    className={styles.startButton}
                    customTag={"a"}
                    href={getCreateAccountUrl("payg")}
                  >
                    Create account
                  </Button>
                </div>
              </td>
              <td headers="thPlanPrice">
                <div className={styles.priceAndButton}>
                  <div className={styles.planPrice}>
                    {currencySymbol}
                    {priceStarter}{" "}
                    <span className={styles.planPricePeriod}>
                      / {priceBillingCycle}
                    </span>
                  </div>
                  <ButtonPrimary
                    className={styles.startButton}
                    customTag={"a"}
                    href={getCreateAccountUrl("starter")}
                  >
                    Start free trial
                  </ButtonPrimary>
                </div>
              </td>
              <td headers="thPlanPrice">
                <div className={styles.priceAndButton}>
                  <div className={styles.planPrice}>
                    {currencySymbol}
                    {pricePro}{" "}
                    <span className={styles.planPricePeriod}>
                      / {priceBillingCycle}
                    </span>
                  </div>
                  <ButtonPrimary
                    className={styles.startButton}
                    customTag={"a"}
                    href={getCreateAccountUrl("pro")}
                  >
                    Start free trial
                  </ButtonPrimary>
                </div>
              </td>
              <td headers="thPlanPrice">
                <div className={styles.priceAndButton}>
                  <div className={styles.planPrice}>
                    {currencySymbol}
                    {priceAgency}{" "}
                    <span className={styles.planPricePeriod}>
                      / {priceBillingCycle}
                    </span>
                  </div>
                  <ButtonPrimary
                    className={styles.startButton}
                    customTag={"a"}
                    href={getCreateAccountUrl("agency")}
                  >
                    Start free trial
                  </ButtonPrimary>
                </div>
              </td>
              {/*          <td headers="thPlanPrice">
            <div className={styles.priceAndButton}>
              <div className={styles.planPrice}>Let’s talk</div>
              <Button className={styles.startButton} customTag={Link} to={'/contact'}>
                Contact sales
              </Button>
            </div>
          </td>*/}
            </tr>
          </thead>

          <CompareTableCategoryBody title={"Compare Plans"} thId={"thCompare"}>
            <CompareTableRow
              title={`Testers included each ${
                billingCycle === "annual" ? "year" : "month"
              }`}
              tooltip={`Test with people from our ${advertedPoolSize} pool of qualified Userbrain testers.`}
              thId={"thTestersIncluded"}
              categoryThId={"thCompare"}
              values={
                billingCycle === "annual"
                  ? ["no", "36 testers", "120 testers", "360 testers"]
                  : ["no", "3 testers", "10 testers", "30 testers"]
              }
              activeColumnMobile={activeColumnMobile}
            />
            <CompareTableRow
              title={"Unused testers roll over"}
              tooltip={
                "Nothing to test this month? No problem. Your unused testers roll over as long as you have an active plan."
              }
              thId={"thRollOver"}
              categoryThId={"thCompare"}
              values={["no", "unlimited", "unlimited", "unlimited"]}
              activeColumnMobile={activeColumnMobile}
            />
            <CompareTableRow
              title={"Admins"}
              tooltip={
                "Admins can do everything collaborators can. Only admins can make payments and purchase testers."
              }
              thId={"thAdmins"}
              categoryThId={"thCompare"}
              values={["1 admin", "1 admin", "3 admins", "20 admins"]}
              activeColumnMobile={activeColumnMobile}
            />
            <CompareTableRow
              title={"Collaborators"}
              tooltip={
                "Collaborators can create tests and order testers. They can also make notes and watch, share & export test videos."
              }
              thId={"thCollaborators"}
              categoryThId={"thCompare"}
              values={[
                "5 collaborators",
                "5 collaborators",
                "20 collaborators",
                "50 collaborators",
              ]}
              activeColumnMobile={activeColumnMobile}
            />
            <CompareTableRow
              title={"Video storage"}
              tooltip={
                "All your videos are stored securely in Userbrain. You can export and download videos at any time."
              }
              thId={"thAdmins"}
              categoryThId={"thCompare"}
              values={["3 months", "1 year", "1 year", "Unlimited"]}
              activeColumnMobile={activeColumnMobile}
            />
          </CompareTableCategoryBody>

          <CompareTableCategoryBody title={"Tester pool"} thId={"thTester"}>
            <CompareTableRow
              title={`Get additional testers for ${currencySymbol}${pricePayAsYouGoTest} each`}
              tooltip={`Tap into our pool of ${advertedPoolSize} quality assured testers whenever you need to.`}
              thId={"thGetTesters"}
              categoryThId={"thTester"}
              values={["yes", "yes", "yes", "yes"]}
              activeColumnMobile={activeColumnMobile}
            />
            <CompareTableRow
              title={"Choose demographics"}
              tooltip={
                "Select testers based on country, age range, gender, language & choose the device type you want to test."
              }
              thId={"thDemographics"}
              categoryThId={"thTester"}
              values={["yes", "yes", "yes", "yes"]}
              activeColumnMobile={activeColumnMobile}
            />
            <CompareTableRow
              title={"Choose language"}
              tooltip={"Get user tests in English, German, or Spanish"}
              thId={"thLanguage"}
              categoryThId={"thTester"}
              values={["yes", "yes", "yes", "yes"]}
              activeColumnMobile={activeColumnMobile}
            />
            <CompareTableRow
              title={"Choose device type"}
              tooltip={
                "Get user tests on tablets, desktops, and mobile devices."
              }
              thId={"thDeviceType"}
              categoryThId={"thTester"}
              values={["yes", "yes", "yes", "yes"]}
              activeColumnMobile={activeColumnMobile}
            />
            <CompareTableRow
              title={"Screening questions"}
              tooltip={
                "Ask screening questions to filter testers and test with your exact target audience."
              }
              thId={"thScreener"}
              categoryThId={"thTester"}
              values={["yes", "yes", "yes", "yes"]}
              activeColumnMobile={activeColumnMobile}
            />
            <CompareTableRow
              title={"Satisfaction guarantee"}
              tooltip={
                "If you’re not happy with one of our testers, you’ll get a new one at no extra cost."
              }
              thId={"thSatisfaction"}
              categoryThId={"thTester"}
              values={["yes", "yes", "yes", "yes"]}
              activeColumnMobile={activeColumnMobile}
            />
          </CompareTableCategoryBody>

          <CompareTableCategoryBody
            title={"Testing features"}
            thId={"thTesting"}
          >
            <CompareTableRow
              title={"Test with your own users"}
              tooltip={
                "Get up to 100 test sessions with your own customers, users, or team members every month. Refills monthly."
              }
              thId={"thYourOwnTesters"}
              categoryThId={"thTesting"}
              values={["no", "yes", "yes", "yes"]}
              activeColumnMobile={activeColumnMobile}
            />
            <CompareTableRow
              title={"Test websites, prototypes & more"}
              tooltip={
                "Test any website with a link or test your Adobe XD, Figma, Sketch, InVision, Framer, Axure, etc., prototype."
              }
              thId={"thTwp"}
              categoryThId={"thTesting"}
              values={["yes", "yes", "yes", "yes"]}
              activeColumnMobile={activeColumnMobile}
            />
            <CompareTableRow
              title={"Mobile app testing"}
              tooltip={""}
              thId={"thNativeApp"}
              categoryThId={"thTesting"}
              values={["yes", "yes", "yes", "yes"]}
              activeColumnMobile={activeColumnMobile}
            />
            <CompareTableRow
              isNew={true}
              title={"Auto-create test tasks"}
              tooltip={
                "Describe your product in your own words and automatically have a custom user test created for you."
              }
              thId={"thAutoCreateTasks"}
              categoryThId={"thTesting"}
              values={["yes", "yes", "yes", "yes"]}
              activeColumnMobile={activeColumnMobile}
            />
            <CompareTableRow
              title={"Templates for test creation"}
              tooltip={
                "Get access to preset user test templates for e-commerce, booking, and more for quick and easy setup in minutes."
              }
              thId={"thUtt"}
              categoryThId={"thTesting"}
              values={["yes", "yes", "yes", "yes"]}
              activeColumnMobile={activeColumnMobile}
            />
            <CompareTableRow
              title={"Different question types"}
              tooltip={
                "Choose one or several different task types for users to complete, from rating scales to written responses to redirects and more."
              }
              thId={"thVtt"}
              categoryThId={"thTesting"}
              values={["yes", "yes", "yes", "yes"]}
              activeColumnMobile={activeColumnMobile}
            />
            <CompareTableRow
              title={"Automated testing"}
              tooltip={
                "Setup regular testing in just one click and change your testing schedule at any time."
              }
              thId={"thAt"}
              categoryThId={"thTesting"}
              values={["no", "yes", "yes", "yes"]}
              activeColumnMobile={activeColumnMobile}
            />
          </CompareTableCategoryBody>

          <CompareTableCategoryBody
            title={"Analyse & share"}
            thId={"thAnalyseAndShare"}
          >
            <CompareTableRow
              title={"Test reports"}
              tooltip={
                "Share a comprehensive summary of your tasks, questions, and notes with your team in a flash."
              }
              thId={"thReports"}
              categoryThId={"thAnalyseAndShare"}
              values={["no", "yes", "yes", "yes"]}
              activeColumnMobile={activeColumnMobile}
            />
            <CompareTableRow
              title={"Transcripts"}
              tooltip={
                "Get instant, accurate speech-to-text transcriptions for your user tests."
              }
              thId={"thTranscripts"}
              categoryThId={"thAnalyseAndShare"}
              values={["no", "yes", "yes", "yes"]}
              activeColumnMobile={activeColumnMobile}
            />
            <CompareTableRow
              title={"AI insights"}
              tooltip={
                "Analyze videos with AI-generated insights. Less manual note-taking, more focus on watching your videos."
              }
              thId={"thTranscripts"}
              categoryThId={"thAnalyseAndShare"}
              values={["no", "yes", "yes", "yes"]}
              activeColumnMobile={activeColumnMobile}
            />
            <CompareTableRow
              title={"Time-based comments"}
              tooltip={
                "Start typing while watching your user test video to add comments at the current timestamp."
              }
              thId={"thComments"}
              categoryThId={"thAnalyseAndShare"}
              values={["yes", "yes", "yes", "yes"]}
              activeColumnMobile={activeColumnMobile}
            />
            <CompareTableRow
              title={"Clips"}
              tooltip={
                "Create video clips of your most important findings and share them."
              }
              thId={"thHighlights"}
              categoryThId={"thAnalyseAndShare"}
              values={["no", "yes", "yes", "yes"]}
              activeColumnMobile={activeColumnMobile}
            />
            <CompareTableRow
              title={"Export videos"}
              tooltip={
                "Download your user test videos as mp4 files to your desktop in seconds."
              }
              thId={"thExportVideos"}
              categoryThId={"thAnalyseAndShare"}
              values={["yes", "yes", "yes", "yes"]}
              activeColumnMobile={activeColumnMobile}
            />
            <CompareTableRow
              title={"Export comments"}
              tooltip={
                "Download your user test comments as CSV files in just a few clicks."
              }
              thId={"thExportComments"}
              categoryThId={"thAnalyseAndShare"}
              values={["yes", "yes", "yes", "yes"]}
              activeColumnMobile={activeColumnMobile}
            />
            <CompareTableRow
              title={"Export test reports"}
              tooltip={
                "Easily export test details and individual responses as CSV files."
              }
              thId={"thExportReports"}
              categoryThId={"thAnalyseAndShare"}
              values={["yes", "yes", "yes", "yes"]}
              activeColumnMobile={activeColumnMobile}
            />
            <CompareTableRow
              title={"Share test videos"}
              tooltip={
                "Easily share Userbrain user test video links with your team via email, text, or Slack."
              }
              thId={"thShare"}
              categoryThId={"thAnalyseAndShare"}
              values={["yes", "yes", "yes", "yes"]}
              activeColumnMobile={activeColumnMobile}
            />
          </CompareTableCategoryBody>

          <CompareTableCategoryBody
            title={"Premium features"}
            thId={"thPremiumFeatures"}
          >
            <CompareTableRow
              title={"Dedicated account manager"}
              tooltip={
                "Need help setting up your tests? One of our experts will help you get the most out of your testing."
              }
              thId={"thAccountManager"}
              categoryThId={"thPremiumFeatures"}
              values={["no", "no", "no", "yes"]}
              activeColumnMobile={activeColumnMobile}
            />
            {/*          <CompareTableRow
            title={'Flexible payment options'}
            tooltip={'Enterprise customers can pay on account (by invoice).'}
            thId={'thFlexiblePayment'}
            categoryThId={'thPremiumFeatures'}
            values={['no', 'no', 'no', 'yes']}
            activeColumnMobile={activeColumnMobile}
          />*/}
          </CompareTableCategoryBody>
        </table>
      </div>
    </div>
  );
}
