import React, { useState } from "react";
import { Link } from "gatsby-plugin-intl";
import { concatClassNames as cn } from "@system42/core";
import {
  Headline2Sans,
  HighlightedText,
  Button,
  ButtonLink,
  Faqs,
  emailSales,
  urlShowMoreFaqCustomer,
} from "../system42";

import {
  testimonialJamie,
  testimonialPascal,
  useLocalCurrency,
  useLocalCurrencySymbol,
  advertedPoolSize,
  testimonialPaul,
} from "../helpers";

import Layout from "../layouts/layout";

import { Brands } from "../components/Brands";
import { ComparePlans } from "../components/ComparePlans";
import { GetStartedBox } from "../components/GetStartedBox";
import { HeaderTitle } from "../components/HeaderTitle";
import { PricingFull2022 } from "../components/PricingFull2022";
import { Testimonials } from "../components/Testimonials";
import { WavySeparator } from "../components/WavySeparator";

import * as helperStyles from "./helpers.module.css";
import * as styles from "./styles/pricing.module.css";

export default function Pricing() {
  const [billingCycle, setBillingCycle] = useState("annual");
  const currency = useLocalCurrency("");
  const currencySymbol = useLocalCurrencySymbol("");

  function handleChangeBillingCycle(e) {
    setBillingCycle(e.target.value);
  }

  return (
    <Layout
      activeMenuItem={"pricing"}
      title={"Pricing"}
      metaDescription={`The easy & affordable user testing tool you’ve been looking for. Access ${advertedPoolSize} quality assured user testers or invite your own users - you choose.`}
    >
      <article>
        <HeaderTitle
          titleWidth={"11em"}
          className={styles.headerTitle}
          subtitle="Free for 14 days — get started in just a few clicks."
        >
          Easy &amp;{" "}
          <span className={styles.titleAffordableRemote}>
            affordable remote
          </span>{" "}
          user testing.
        </HeaderTitle>

        <div
          className={cn(helperStyles.container, styles.containerPricingFull)}
        >
          <PricingFull2022
            currency={currency}
            currencySymbol={currencySymbol}
            billingCycle={billingCycle}
            onChangeBillingCycle={handleChangeBillingCycle}
          />
        </div>

        <WavySeparator />

        <Headline2Sans
          className={styles.headingComparePlans}
          id={"compare-plans"}
        >
          Compare plans
        </Headline2Sans>
        <ComparePlans
          className={styles.comparePlans}
          billingCycle={billingCycle}
        />

        <div className={helperStyles.container}>
          <Testimonials
            className={helperStyles.spacingLg}
            title={"Trusted by UX & product teams around the world:"}
            titleWidth={"17em"}
            testimonials={[
              testimonialJamie,
              testimonialPascal,
              testimonialPaul,
            ]}
          />
        </div>

        <Brands className={helperStyles.spacingLg} hideDescription={true} />

        <div className={helperStyles.containerFaqs}>
          <Faqs
            className={helperStyles.spacingLg}
            footerText={"We still didn’t answer all of your questions?"}
            footerActions={
              <>
                <Button customTag={"a"} href={`mailto:${emailSales}`}>
                  Contact us
                </Button>
                <ButtonLink customTag={"a"} href={urlShowMoreFaqCustomer}>
                  See more FAQs
                </ButtonLink>
              </>
            }
            questions={[
              {
                question: "What exactly do I get with one user test?",
                answer:
                  "You will get a 5-15min video screen and audio recording from a real person testing your website, web app, or prototype in their normal surroundings. This person will complete your tasks and speak out loud while they are using your product. This will help you spot usability issues and improve the UX of your product or service. You can find Userbrain test examples here.",
              },
              {
                question: "Who will be testing?",
                answer: (
                  <>
                    Userbrain testers are everyday people, from all around the
                    world. You can even target their demographics based on
                    country, gender, age-range, language, and device type.{" "}
                    <Link to={"/features/tester-pool"}>
                      Find out more here.
                    </Link>
                  </>
                ),
              },
              {
                question: "How many tests do I need to get good results?",
                answer:
                  "It always depends on your end goal, but UX experts say to test “with no more than 5 users in one round of testing and running as many small tests as you can afford.” *Source: Nielsen Norman Group",
              },
              {
                question: "What if I’m not happy with the user test I receive?",
                answer:
                  "We offer a 100% satisfaction guarantee. This means that if you’re not happy with a test, we’ll provide you a new one.",
              },
              {
                question: "Can I test with my own users?",
                answer:
                  "Yes. You can test with your own users by subscribing to one of our plans.",
              },
              {
                question: "What are credits?",
                answer:
                  "1 credit is worth a 5-20 minute screen and audio recording carried out by 1 Userbrain tester. You can get longer testing sessions and more targeting options for additional credits. Credits never expire.",
              },
            ]}
          />
        </div>
        <div className={helperStyles.container}>
          <GetStartedBox
            className={helperStyles.spacingLg}
            title={
              <>
                Try Userbrain
                <br />
                <HighlightedText cursor>
                  &nbsp;&nbsp;for free.&nbsp;
                </HighlightedText>
              </>
            }
            textBottom={
              <>
                Your 14-day free trial includes <br />
                <HighlightedText>
                  <strong>2 Userbrain testers + invite 5 of your own.</strong>
                </HighlightedText>
                <br />
                No credit card required.
              </>
            }
          />
        </div>
      </article>
    </Layout>
  );
}
