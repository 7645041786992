import React from "react";
import { concatClassNames as cn } from "@system42/core";
// import { } from '../../system42'
import * as styles from "./toggleBillingCycle.module.css";

export function ToggleBillingCycle(props) {
  const { className, onChange, value, ...containerProps } = props;

  return (
    <div
      className={cn(className, styles.toggleBillingCycle)}
      {...containerProps}
    >
      <label className={cn(value === "annual" && styles.activeAnnual)}>
        <input
          checked={value === "annual"}
          type="radio"
          name={"billingCycle"}
          value={"annual"}
          onChange={onChange}
        />
        Yearly
        <span>Save 20%</span>
      </label>
      <label className={cn(value === "monthly" && styles.activeMonthly)}>
        <input
          checked={value === "monthly"}
          type="radio"
          name={"billingCycle"}
          value={"monthly"}
          onChange={onChange}
        />{" "}
        Monthly
      </label>
    </div>
  );
}
