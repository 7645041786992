import React from "react";

export function CompareTableCategoryBody(props) {
  const { title, thId, children, ...containerProps } = props;

  return (
    <tbody {...containerProps}>
      <tr>
        <th id={thId}>{title}</th>
        <td />
        <td />
        <td />
        <td />
      </tr>
      {children}
    </tbody>
  );
}
